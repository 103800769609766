import { createColumnHelper } from "@tanstack/react-table";
import { getCsrfToken, signIn, useSession } from "next-auth/react";
import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { Badge, Box, Container, Flex, Link, Text } from "theme-ui";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { BrandSelector } from "../components/layouts/BrandSelector";
import { ChannelDistribution } from "../components/layouts/ChannelDistribution";
import { DashboardOverview } from "../components/layouts/DashboardOverview";
import { DelinquencyMetrics } from "../components/layouts/DelinquencyMetrics";
import { EmailFunnelAndDeliveryMetrics } from "../components/layouts/EmailFunnelAndDeliveryMetrics";
import { EngagementMetrics } from "../components/layouts/EngagementMetrics";
import { fetchClient, fetchData } from "../components/pipes/withData";
import { formatDate, formatNumber, formatPercentage } from "../utils";
import { Client, Brand } from "../utils/dashboardEndpoints";
import useSWR from "swr";
import axios from "axios";
import { sortBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { theme } from "../themes";
import { useClientId } from "../views/content-home/useClientID";

export interface DashboardProps {
  communicationFunnelCurrentMonth: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  communicationFunnelPreviousMonth: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  accountEmailFunnelCurrentMonth: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  accountEmailFunnelPreviousMonth: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  activeAccountMonthlyTrend: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  activeAccountsByMonth: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  emailClickRate: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  emailOpenRate: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  smsOptOutRate: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  commsClickRate: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  commsOpenRate: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  channelDistribution: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  channelDistributionMTD: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  commsClickRateMTD: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  commsOpenRateMTD: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  communicationRateMTD: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  communicationRatePreviousMonth: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  paymentTrends: {
    value:
      | (number | string)
      | {
          all?: Object | null;
          current?: Object | null;
          prior?: Object | null;
          series?: Object | null;
        };
  };
  accountsByDelinquencyBucketMTD: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  accountsByDelinquencyBucket: {
    value:
      | (number | string)
      | {
          all?: Object | null;
          current?: Object | null;
          prior?: Object | null;
          series?: Object | null;
        };
  };
  paymentRate: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  paymentRateMTD: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  emailClickRateMTD: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  emailOpenRateMTD: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  emailUnsubscribeRate: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  maxEmailsSentToAccount: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  monthlyEmailCadence: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  monthlyPaymentTrends: {
    value:
      | (number | string)
      | {
          all?: Object | null;
          current?: Object | null;
          prior?: Object | null;
          series?: Object | null;
        };
  };
  paymentAmountsByMonth: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  paymentCountsByMonth: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  placementsAndRetractions: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  placementsMTD: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  sendRate: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  smsClickRate: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  smsClickRateMTD: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  smsDeliveryRate: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  smsDeliveryRateMTD: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  uniqueAccountClickRateMTD: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  uniqueAccountSendsAndClick: {
    value:
      | (number | string)
      | {
          all?: Object | null;
          current?: Object | null;
          prior?: Object | null;
          series?: Object | null;
        };
  };
  emailEngagementMonthlyTotal: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  vmDeliveryRate: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  vmDeliveryRateMTD: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
  vmOptOutRate: {
    value:
      | (number | string)
      | { all?: Object | null; current?: Object | null; prior?: Object | null };
  };
}

export interface HomeProps {
  data?: any;
  displayMenu: boolean;
  clientDetails: any;
  brands: Brand[];
  isClientIdSimulated?: any;
  isSuperAdmin?: any;
  clients?: Client[];
  smsMetrics: boolean;
  vmMetrics: boolean;
  pageTitle: string;
}

export interface QueryResultKeys {
  ACCOUNT_EMAIL_SENT_RATE?: string | number;
  ACCOUNT_EMAIL_UNSUB_RATE?: string | number;
  ACTIVE_ACCT_ID_CNT?: string | number;
  ["MAX(EMAILS_SENT_SUM)"]?: string | number;
  PAYMENT_TRANS_SUM_AMT?: string | number;
  PAYMENT_TRANS_SUM?: string | number;
  PLACEMENT_ID_CNT?: string | number;
}

export interface EndpointResult {
  data?: DashboardProps;
  statusCode: number;
}

export interface EndpointResponse {
  error: string;
  path: string;
  queryId: string;
  result: QueryResultKeys[];
}

export interface QueryResult {
  data: EndpointResponse;
  queryId: string;
  result: EndpointResult[];
}

const DashboardHome = () => {
  const [selectedBrandId, setSelectedBrandId] = useState("");

  const { data } = useSession();
  const clientIdToFetch = useClientId();

  const { data: clientDetails, isLoading: isLoadingClientDetails } = useSWR(
    `/api/v2/client/${data!.clientId}/details`,
    async () => {
      const clientDataDetails = await fetchClient(
        clientIdToFetch!,
        data!.accessToken!
      );

      return clientDataDetails.data;
    }
  );

  const { data: fetchedData, isLoading: isLoadingFetchData } = useSWR<
    Record<any, any>
  >(
    clientDetails
      ? `/api/v2/client/${clientDetails.id}?brandId=${selectedBrandId}`
      : null,
    async () => {
      try {
        const response = await fetchData(
          clientIdToFetch!,
          data!.accessToken,
          selectedBrandId
        );

        return response;
      } catch (error) {
        return [];
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: fetchedDataClient, isLoading: isLoadingClientMetrics } =
    useSWR<{
      smsMetrics: boolean;
      vmMetrics: boolean;
      brands: any[];
    }>(
      clientDetails
        ? `${process.env.API_URL}/api/v2/clients/${clientIdToFetch}?type=metrics`
        : null,
      async () => {
        try {
          const clientApiResponse = await axios.get(
            `${process.env.API_URL}/api/v2/clients/${clientIdToFetch}`,
            {
              headers: {
                Authorization: `Bearer ${data!.accessToken}`,
              },
            }
          );

          return {
            smsMetrics: clientApiResponse?.data?.properties?.smsMetrics,
            vmMetrics: clientApiResponse?.data?.properties?.vmMetrics,
            brands: clientApiResponse?.data?.brands.filter(
              (brand: Brand) =>
                brand.properties.active === true &&
                brand.reference.toUpperCase() !==
                  clientApiResponse?.data?.reference.toUpperCase() &&
                brand.name.toUpperCase() !==
                  clientApiResponse?.data?.name.toUpperCase()
            ),
          };
        } catch (error) {
          return {
            smsMetrics: false,
            vmMetrics: false,
            brands: [],
          };
        }
      },
      {
        revalidateOnFocus: false,
      }
    );

  const smsEnabled = fetchedDataClient?.smsMetrics;
  const vmEnabled = fetchedDataClient?.vmMetrics;

  const dashboardOverviewRef = useRef(null);
  const engagementMetricsRef = useRef(null);
  const delinquencyMetricsRef = useRef(null);
  const channelDistributionRef = useRef(null);
  const emailFunnelAndDeliveryMetricsRef = useRef(null);

  const onSelect = async (brandId?: string) => {
    setSelectedBrandId(brandId ?? "");
  };

  if (isLoadingFetchData || isLoadingClientMetrics || isLoadingClientDetails) {
    return (
      <Flex sx={{ flexDirection: "column", width: "100%" }}>
        <BrandSelector
          brands={clientDetails?.brands ?? []}
          clientName={clientDetails?.name ?? ""}
          defaultId={selectedBrandId ? selectedBrandId : ""}
          onSelect={onSelect}
        />
        <Container sx={{ p: 4 }}>
          <FontAwesomeIcon
            color={theme.colors.primary}
            icon={faSpinner}
            style={{
              marginRight: 8,
            }}
            spin
          />

          <Text variant="cell">Loading data...</Text>
        </Container>
      </Flex>
    );
  }

  if (Object.keys(fetchedData ?? {}).length < 1) {
    return (
      <Container>
        <Flex sx={{ p: 4 }}>
          <Container>
            <Text>Something went wrong. Please try again later.</Text>
          </Container>
        </Flex>
      </Container>
    );
  }

  const {
    communicationFunnelCurrentMonth,
    communicationFunnelPreviousMonth,
    accountEmailFunnelCurrentMonth,
    accountEmailFunnelPreviousMonth,
    activeAccountMonthlyTrend,
    activeAccountsByMonth,
    emailClickRate,
    emailOpenRate,
    smsOptOutRate,
    commsClickRate,
    commsOpenRate,
    channelDistribution,
    channelDistributionMTD,
    commsClickRateMTD,
    commsOpenRateMTD,
    communicationRateMTD,
    communicationRatePreviousMonth,
    paymentTrends,
    accountsByDelinquencyBucket,
    accountsByDelinquencyBucketMTD,
    paymentRate,
    paymentRateMTD,
    emailClickRateMTD,
    emailOpenRateMTD,
    emailUnsubscribeRate,
    maxEmailsSentToAccount,
    monthlyEmailCadence,
    monthlyPaymentTrends,
    paymentAmountsByMonth,
    paymentCountsByMonth,
    placementsAndRetractions,
    placementsMTD,
    sendRate,
    sendRatePreviousMonth,
    smsClickRate,
    smsClickRateMTD,
    smsDeliveryRate,
    smsDeliveryRateMTD,
    uniqueAccountClickRateMTD,
    uniqueAccountSendsAndClick,
    emailEngagementMonthlyTotal,
    vmDeliveryRate,
    vmDeliveryRateMTD,
    vmOptOutRate,
  } = fetchedData ?? {};

  const columnHelper = createColumnHelper<QueryResultKeys>();

  const monthlyEmailCadenceColumns = [
    columnHelper.accessor("MTH", {
      cell: (info: any) => {
        const splitDate = info
          .getValue()
          .split("-")
          .map((n: string) => Number(n));
        return formatDate({
          month: "long",
          year: "numeric",
        }).format(new Date(Date.UTC.apply(null, splitDate)));
      },
      header: () => <span>Month</span>,
    }),
    columnHelper.accessor("TOTAL_ACTIVE_ACCOUNTS", {
      cell: (info: any) => formatNumber(info.getValue()),
      header: () => <span>Active Accounts</span>,
    }),
    columnHelper.accessor("TOTAL_EMAILS_SENT", {
      cell: (info: any) => formatNumber(info.getValue()),
      header: () => <span>Total Sends</span>,
    }),
    columnHelper.accessor("TOTAL_EMAILS_DELIVERED", {
      cell: (info: any) => formatNumber(info.getValue()),
      header: () => <span>Total Deliver</span>,
    }),
    columnHelper.accessor("TOTAL_EMAILS_OPENED", {
      cell: (info: any) => formatNumber(info.getValue()),
      header: () => <span>Total Opens</span>,
    }),
    columnHelper.accessor("TOTAL_EMAILS_CLICKED", {
      cell: (info: any) => formatNumber(info.getValue()),
      header: () => <span>Total Clicks</span>,
    }),
  ];

  const monthlyEmailEngagementColumns = [
    columnHelper.accessor("MTH", {
      cell: (info: any) => {
        const splitDate = info
          .getValue()
          .split("-")
          .map((n: string) => Number(n));
        return formatDate({
          month: "long",
          year: "numeric",
        }).format(new Date(Date.UTC.apply(null, splitDate)));
      },
      header: () => <span>Month</span>,
    }),
    columnHelper.accessor("ACTIVE_ACCOUNTS_CNT", {
      cell: (info: any) => formatNumber(Number(info.getValue())),
      header: () => <span>Active Accounts</span>,
    }),
    columnHelper.accessor("ACCOUNT_WITH_EMAIL_SENT_CNT", {
      cell: (info: any) => formatNumber(Number(info.getValue())),
      header: () => <span>Accounts with Send</span>,
    }),
    columnHelper.accessor("ACCOUNT_WITH_EMAIL_DELIVER_CNT", {
      cell: (info: any) => formatNumber(Number(info.getValue())),
      header: () => <span>Accounts with Deliver</span>,
    }),
    columnHelper.accessor("ACCOUNT_WITH_EMAIL_OPEN_CNT", {
      cell: (info: any) => formatNumber(Number(info.getValue())),
      header: () => <span>Accounts with Open</span>,
    }),
    columnHelper.accessor("ACCOUNT_WITH_EMAIL_CLICK_CNT", {
      cell: (info: any) => formatNumber(Number(info.getValue())),
      header: () => <span>Accounts with Click</span>,
    }),
    columnHelper.accessor("SEND_RATE", {
      cell: (info: any) => formatPercentage(1, Number(info.getValue())),
      header: () => <span>Send Rate</span>,
    }),
    columnHelper.accessor("DELIVER_RATE", {
      cell: (info: any) => formatPercentage(1, Number(info.getValue())),
      header: () => <span>Deliver Rate</span>,
    }),
    columnHelper.accessor("OPEN_RATE", {
      cell: (info: any) => formatPercentage(1, Number(info.getValue())),
      header: () => <span>Open Rate</span>,
    }),
    columnHelper.accessor("CLICK_RATE", {
      cell: (info: any) => formatPercentage(1, Number(info.getValue())),
      header: () => <span>Click Rate</span>,
    }),
  ];

  return (
    <Flex sx={{ flexDirection: "column", width: "100%" }}>
      <BrandSelector
        brands={clientDetails?.brands ?? []}
        clientName={clientDetails?.name ?? ""}
        defaultId={selectedBrandId ? selectedBrandId : ""}
        onSelect={onSelect}
      />
      <DashboardOverview
        activeAccountsByMonth={activeAccountsByMonth}
        activeAccountMonthlyTrend={activeAccountMonthlyTrend}
        monthlyPaymentTrends={monthlyPaymentTrends}
        paymentAmountsByMonth={paymentAmountsByMonth}
        paymentCountsByMonth={paymentCountsByMonth}
        placementsMTD={placementsMTD}
        scrollRef={dashboardOverviewRef}
      />
      <EngagementMetrics
        emailClickRate={emailClickRate}
        emailOpenRate={emailOpenRate}
        commsClickRate={commsClickRate}
        commsOpenRate={commsOpenRate}
        commsClickRateMTD={commsClickRateMTD}
        commsOpenRateMTD={commsOpenRateMTD}
        paymentTrends={paymentTrends}
        emailClickRateMTD={emailClickRateMTD}
        emailOpenRateMTD={emailOpenRateMTD}
        paymentRate={paymentRate}
        paymentRateMTD={paymentRateMTD}
        placementsAndRetractions={placementsAndRetractions}
        scrollRef={engagementMetricsRef}
        smsEnabled={Boolean(smsEnabled)}
        smsClickRate={smsClickRate}
        smsClickRateMTD={smsClickRateMTD}
        smsDeliveryRate={smsDeliveryRate}
        smsDeliveryRateMTD={smsDeliveryRateMTD}
        uniqueAccountClickRateMTD={uniqueAccountClickRateMTD}
        uniqueAccountSendsAndClick={uniqueAccountSendsAndClick}
        vmEnabled={Boolean(vmEnabled)}
        vmDeliveryRate={vmDeliveryRate}
        vmDeliveryRateMTD={vmDeliveryRateMTD}
        vmOptOutRate={vmOptOutRate}
      />
      <DelinquencyMetrics
        accountsByDelinquencyBucket={accountsByDelinquencyBucket}
        accountsByDelinquencyBucketMTD={accountsByDelinquencyBucketMTD}
        scrollRef={delinquencyMetricsRef}
      />
      <ChannelDistribution
        channelDistributionMTD={channelDistributionMTD}
        channelDistribution={channelDistribution}
        scrollRef={channelDistributionRef}
      />
      <EmailFunnelAndDeliveryMetrics
        communicationFunnelCurrentMonth={communicationFunnelCurrentMonth}
        communicationFunnelPreviousMonth={communicationFunnelPreviousMonth}
        accountEmailFunnelCurrentMonth={accountEmailFunnelCurrentMonth}
        accountEmailFunnelPreviousMonth={accountEmailFunnelPreviousMonth}
        smsOptOutRate={smsOptOutRate}
        communicationRateMTD={communicationRateMTD}
        communicationRatePreviousMonth={communicationRatePreviousMonth}
        emailEngagementMonthlyTotal={emailEngagementMonthlyTotal}
        emailUnsubscribeRate={emailUnsubscribeRate}
        maxEmailsSentToAccount={maxEmailsSentToAccount}
        monthlyEmailEngagementColumns={monthlyEmailEngagementColumns}
        monthlyEmailCadence={monthlyEmailCadence}
        monthlyEmailCadenceColumns={monthlyEmailCadenceColumns}
        scrollRef={emailFunnelAndDeliveryMetricsRef}
        sendRate={sendRate}
        sendRatePreviousMonth={sendRatePreviousMonth}
        smsEnabled={Boolean(smsEnabled)}
      />
    </Flex>
  );
};

const AdminClientList = () => {
  const { data } = useSession();
  const { data: clientsData, isLoading } = useSWR<Client[]>(
    `/api/v2/clients`,
    async () => {
      try {
        const csrfToken = await getCsrfToken();
        const response = await axios.get(
          `${process.env.API_URL}/api/v2/clients?csrf=${csrfToken}`,
          {
            headers: {
              Authorization: `Bearer ${data?.accessToken}`,
            },
          }
        );

        return sortBy(response.data, (cl) => cl.name.toLowerCase());
      } catch (error) {
        return [];
      }
    },
    {
      fallbackData: [],
      revalidateOnFocus: false,
    }
  );

  return (
    <>
      <Container sx={{ paddingTop: "63px" }}>
        <Box
          sx={{
            display: "grid",
            p: 4,
            gridGap: 4,
            gridTemplateColumns: "repeat(3, 1fr)",
            paddingBottom: "1.5rem",
            paddingTop: "1.5rem",
            "@media screen and (max-width: 64em)": {
              gridTemplateColumns: "repeat(1, 1fr)",
            },
          }}
        >
          <Flex
            sx={{
              flexFlow: "row",
              minWidth: "100%",
            }}
          >
            <Text
              variant="strong"
              sx={{
                display: "flex",
                alignItems: "center",
                paddingRight: "32px",
              }}
            >
              Retain Client List
            </Text>
          </Flex>
        </Box>
      </Container>
      <Container paddingLeft={4} paddingTop={2} paddingBottom={4}>
        <>
          {isLoading ? (
            <>
              <FontAwesomeIcon
                color={theme.colors.primary}
                icon={faSpinner}
                style={{
                  marginRight: 8,
                }}
                spin
              />

              <Text variant="cell">Loading data...</Text>
            </>
          ) : (
            <>
              {Array.isArray(clientsData) && clientsData.length > 0 ? (
                clientsData.map((client) => {
                  return (
                    <div key={`${client.name}-${client.id}`}>
                      <Link
                        variant="link"
                        target="new"
                        href={`/?client_id=${client.id}`}
                      >
                        {client.name}
                      </Link>{" "}
                      {client.properties.active && <Badge>active</Badge>}
                    </div>
                  );
                })
              ) : (
                <Box>-</Box>
              )}
            </>
          )}
        </>
      </Container>
    </>
  );
};

export const Home = () => {
  const { data } = useSession();
  const { query } = useRouter();
  const { status } = useSession({ required: true });

  useEffect(() => {
    if (data) {
      if (data.error === "RefreshAccessTokenError") {
        signIn()
          .then(() => {})
          .catch(() => {});
      }
    }
  }, [data]);

  if (status !== "authenticated") {
    return null;
  }

  const { clientId, groups } = data || {};

  if (groups?.includes("SuperAdmins") ?? false) {
    const isClientIdSimulated = (query?.client_id || "").length > 0;

    if (!isClientIdSimulated) {
      return <AdminClientList />;
    }
  }

  return <DashboardHome />;
};

export default Home;
