import React from "react";
import { Box, Container, Flex, Text, Select } from "theme-ui";
import { theme } from "../../../themes/base";
import { Brand } from "../../../utils/dashboardEndpoints";

export interface BrandSelectorProps {
  brands: Brand[];
  clientName: string;
  defaultId: string;
  onSelect: (event: any) => void;
}

export function BrandSelector(
  props: BrandSelectorProps = {
    brands: [],
    clientName: "",
    defaultId: "",
    onSelect: () => {},
  }
) {
  const displayBrandSelector = props.brands.length >= 1;
  return (
    <Container
      sx={{
        display: "flex",
        background: theme.colors.background,
      }}
    >
      <Text
        variant="clientName"
        sx={{
          display: "flex",
          alignItems: "end",
          padding: "1.5rem 0 1.5rem 2rem",
        }}
      >
        {props.clientName}
      </Text>
      <Flex
        sx={{
          flexFlow: "column",
          minHeight: "100%",
          justifyContent: "center",
          padding: "1rem 1rem 1rem 1.5rem",
          minWidth: "200px",
          width: "20%",
          ...(!displayBrandSelector && {
            display: "none",
          }),
        }}
      >
        <Select
          sx={{
            color: theme.colors.charcoal50,
          }}
          arrow={
            <Box
              as="svg"
              // @ts-ignore
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill={theme.colors.charcoal50}
              sx={{
                ml: -28,
                alignSelf: "center",
                pointerEvents: "none",
              }}
            >
              <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
            </Box>
          }
          defaultValue="All brands"
          onChange={(event) => {
            const selected = event.target.value;

            props.onSelect(selected === "all" ? null : selected);
          }}
        >
          <option value="all">All brands</option>
          {props.brands.map((brand) => (
            <option
              key={`brand-list-${brand.id}`}
              id={brand.id}
              value={brand.id}
              selected={brand.id === props.defaultId}
            >
              {brand.internalDisplayName
                ? brand.internalDisplayName
                : brand.reference.replaceAll("_", " ").toLocaleLowerCase()}
            </option>
          ))}
        </Select>
      </Flex>
    </Container>
  );
}
export default BrandSelector;
